export default () => ({
  hasLoadError: false,
  isVisibleErrorReasonModal: false,
  adminPermission: null,
  idx: null,
  job_idx: null,
  run: null,
  tab: null,
  search_str: null,
  is_screenshot: null,
  has_result: null,
  best_ranking: null,
  job_type: null,
  file_url: null,
  campaign_idx: null,
  campaign_title: null,
  adv_idx: null,
  adv_name: null,
  adv_email: null,
  state: 'ACTIVE',
  postingList: [],
  isSaving: false,
  timelineList: [],
  createMemoSidebar: {
    isVisible: false,
    isSaving: false,
    text: null,
  },
})
