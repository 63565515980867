<template>
  <BCard title="기본 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="JOB_IDX">
          <TagbyLinkInput
            :value="state.job_idx"
            :to="{ name: 'screenshot-job-detail', params: { idx: state.job_idx } }"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="JOB_TYPE">
          <BFormInput
            :value="state.job_type"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="CAMPAIGN_IDX">
          <TagbyLinkInput
            :disabled="!state.campaign_idx"
            :value="state.campaign_idx"
            :to="{ name: 'campaign-compt-active-detail', params: { idx: state.campaign_idx } }"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="CAMPAIGN_TITLE">
          <BFormInput
            :value="state.campaign_title"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="ADV_IDX">
          <TagbyLinkInput
            :disabled="!state.adv_idx"
            :value="state.adv_idx"
            :to="{ name: 'member-adv-view', params: { idx: state.adv_idx } }"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="ADV_NAME">
          <BFormInput
            :value="state.adv_name"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="SEARCH_STR">
          <BFormInput
            :value="state.search_str"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="RUN">
          <BFormInput
            :value="state.run"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="TAB">
          <BFormInput
            :value="state.tab"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="BEST_RANKING">
          <BFormInput
            :value="state.best_ranking"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,

    TagbyLinkInput,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
