<template>
  <TagbyActionCard>
    <template #left>
      <ImgDownloadButton />
    </template>

    <template #right>
      <CreateMemoButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import ImgDownloadButton from './ImgDownloadButton.vue'
import CreateMemoButton from './CreateMemoButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,
    ImgDownloadButton,
    CreateMemoButton,
  },
  setup() {

  },
})
</script>
