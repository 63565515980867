<template>
  <TagbyActionButton
    :isValid="state.file_url != null"
    :isSaving="isDownloading"
    variant="outline-secondary"
    text="스크린샷 다운로드"
    @click="downloadImg"
  />
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import downloadFile from '@/utils/downloadFile'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const isDownloading = ref(false)
    const downloadImg = () => {
      isDownloading.value = true
      downloadFile(state.file_url).finally(() => {
        isDownloading.value = false
      })
    }
    return {
      state,
      isDownloading,
      downloadImg,
    }
  },
})
</script>
