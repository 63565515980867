import useToast from '@/utils/toast'
import state from './state'
import getters from './getters'
import api from './api'
import useErrorReason from './useErroReason'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
    turnOffErrorReasonModal,
  } = useErrorReason()

  const getAdminMe = () => api.getAdminMe().then(response => {
    state.adminPermission = response.data.data.permission
  })

  const getDetail = () => api.getDetail({
    idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.name = resData.name
    state.job_idx = resData.job_idx
    state.run = resData.run
    state.tab = resData.tab
    state.search_str = resData.search_str
    state.is_screenshot = resData.is_screenshot
    state.has_result = resData.has_result
    state.best_ranking = resData.best_ranking
    state.job_type = resData.job_type
    state.file_url = resData.file_url
    state.campaign_idx = resData.campaign_idx
    state.campaign_title = resData.campaign_title
    state.adv_idx = resData.adv_idx
    state.adv_name = resData.adv_name
    state.adv_email = resData.adv_email
    state.state = resData.state
    state.postingList = resData.posting_list
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getTimelineList = () => api.getTimelineList({
    info_table: 'influencer_info',
    info_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(err => {
    makeToast('danger', '타임라인을 가져오는데 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  return {
    getAdminMe,
    getDetail,
    turnOffErrorReasonModal,
    getTimelineList,
  }
}
