<template>
  <BCard title="포스팅 리스트">
    <BTable
      :fields="fields"
      :items="state.postingList"
      show-empty
    >
      <template #cell(sns)="data">
        <TagbySnsColumn :sns="parseSnsObject(data.item)" />
      </template>

      <template #cell(url)="data">
        <BLink
          :href="data.value"
          target="_blank"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(log_idx)="data">
        {{ resolveLogIdx(data.item) }}
      </template>

      <template #cell(ranking)="data">
        {{ data.value === -1 ? '-' : data.value }}
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    TagbySnsColumn,
  },
  setup() {
    const fields = [
      { key: 'sns', label: 'sns' },
      { key: 'url', label: 'url' },
      { key: 'state', label: 'state' },
      { key: 'ranking', label: 'ranking' },
    ]

    const parseSnsObject = item => ({
      uid: item.username,
      service: 'NAVER_BLOG',
      profile_img_url: item.user_img_url,
    })

    return {
      state,
      getters,
      fields,
      parseSnsObject,
    }
  },
})
</script>
