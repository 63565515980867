<template>
  <BCard title="스크린샷 정보">
    <BImg
      :src="state.file_url"
      fluid
    />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BImg,
} from 'bootstrap-vue'
import state from '../../state'

export default defineComponent({
  components: {
    BCard,
    BImg,
  },
  setup() {
    return {
      state,
    }
  },
})
</script>
